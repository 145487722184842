import { DEFAULT_PAGINATION } from '@/util/const';
import { PerPageStorage } from '@/models/perPageStorage';

export default {
  data: () => ({
    moreThanOnePageVisible: false,
    storage: null,
  }),

  created() {
    this.initStorage();
  },

  methods: {
    initStorage() {
      this.storage = new PerPageStorage();
    },

    setPaginationParams(pagination) {
      return {
        page: +this.$route.query.page,
        size: +this.$route.query.size,
        ...pagination
      };
    },

    async setDefaultPagination() {
      let defaultPagination = {};
      if (!this.$route.query.page) defaultPagination.page = DEFAULT_PAGINATION.PAGE;
      if (!this.$route.query.size) defaultPagination.size = this.storage.getPerPageCount();
      if (defaultPagination.page || defaultPagination.size)
        await this.$router.replace({ query: { ...defaultPagination, ...this.$route.query } });
    },

    async updatePageSize(size) {
      const pagination = {
        size,
        page: DEFAULT_PAGINATION.PAGE
      };
      await this.$router.replace({query: {...this.$route.query, ...pagination}});
    },

    async setPageSize(size) {
      this.storage.savePerPageCount(size);
    },

    getDefaultPagination() {
      return {
        page: DEFAULT_PAGINATION.PAGE,
        size: this.storage.getPerPageCount()
      };
    },

    getDefaultPageSize() {
      return {
        size: this.storage.getPerPageCount()
      };
    },

    async paginationChange() {
      this.moreThanOnePageVisible = false;
      await this.setPaginationQuery();
      this.$emit('paginationChange');
    },

    async setPaginationQuery() {
      await this.$router.replace({query: {...this.$route.query, page: this.pagination.page, size: this.pagination.size }});
    },

    async onLoadMoreClick() {
      this.moreThanOnePageVisible = true;
      this.pagination.loadMore();
      await this.setPaginationQuery();
      this.$emit('loadMore');
    },
  }
};
