import api from '@/services/api/api-driving-schools.js';

export class DrivingSchoolService {
  async list(query) {
    try {
      let res = await api.list(query);
      if (!res.results) throw new Error('Something wrong and candidates not loading. Please, reload page or try later.');
      let data = {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        },
        totals: res.totals
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async list_applications(query) {
    try {
      let res = await api.list_applications(query);
      if (!res.results) throw new Error('Something wrong and candidates not loading. Please, reload page or try later.');
      let data = {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        }
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async list_transactions(query) {
    try {
      let res = await api.list_transactions(query);
      if (!res.results) throw new Error('Something wrong and candidates not loading. Please, reload page or try later.');
      let data = {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        }
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async items(query) {
    try {
      return await api.items(query);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async post(data) {
    try {
      return await api.post({...data, slug: data.slug.toLowerCase()});
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async put(ID, data) {
    try {
      return await api.put(ID, {...data, slug: data.slug.toLowerCase()});
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async remove(ID) {
    try {
      return await api.remove(ID);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async report_csv(query) {
    try {
      return await api.report_csv(query);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async make_payout(data) {
    try {
      return await api.make_payout(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}