import AuthRepoLocalStorage from '../services/api/util/auth-repo-local-storage';
import { DEFAULT_PAGINATION } from '@/util/const';

let instance;

export class PerPageStorage {
  constructor() {
    if (instance) return instance;

    instance = this;
    this.storage = new AuthRepoLocalStorage();
    this.storage_title = 'per_page_count';
  }

  savePerPageCount(value) {
    this.storage.store(this.storage_title, +value);
  }

  getPerPageCount() {
    return this.storage.get(this.storage_title) ? +this.storage.get(this.storage_title) : DEFAULT_PAGINATION.SIZE;
  }
}
