<template lang="pug">
  .ds-page--list
    list(
      :headers="headers"
      :list="list"
      :totals="totals"
      :loading="loading"
      :pagination="pagination"
      :primaryPagination="primaryPagination"
      @loadMore="loadMore"
      @change:sorting="updateSorting"
      @needToUpdate="loadList({})"
    )
    portal(to="ds-pagination")
      pagination(:pagination="pagination" :loading="loading" @change="loadList({})")
</template>

<script>
import paginationMixin from '@/mixins/pagination.mixin'
import { DrivingSchoolService } from '../../core/drivingSchool-service'
import { DRIVING_SCHOOL_HEADERS_LIST } from '../../core/drivingSchool-const'
import PaginationModel from '@/models/paginationModel'

export default {
  name: 'DrivingSchoolPageList',

  mixins: [paginationMixin],

  data: () => ({
    list: [],
    totals: {},
    loading: false,
    pagination: {},
    primaryPagination: {},
    headers: DRIVING_SCHOOL_HEADERS_LIST
  }),

  created() {
    this.setDefaultPagination()
    this.preSelectPageData()
  },

  async mounted() {
    await this.loadList({})
  },

  methods: {
    async loadMore() {
      if (!this.pagination) return
      await this.onLoadMoreClick()
      await this.loadList({more: true})
    },

    async loadList({more = false}, query) {
      try {
        this.loading = true
        let {results, pagination, totals} = await new DrivingSchoolService().list({...this.$route.query, ...query})
        if (more) this.list.push(...results)
        else this.list = results
        this.totals = totals
        await this.$nextTick()
        this.pagination = new PaginationModel(this.setPaginationParams(pagination))
        if (!more) this.setPrimaryPagination()
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async updateSorting(ordering) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ordering: ordering}})
      await this.loadList({})
    },

    setPrimaryPagination() {
      this.primaryPagination = { page: this.pagination.page, size: this.pagination.size }
    },

    async setQuery(data, load) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...data}})
      if (load) await this.loadList({})
    },

    async preSelectPageData() {
      if (!this.$route.query.ordering) await this.setQuery({ordering: 'name'}, false)
    }
  },

  components: {
    list: () => import('./SchoolList.vue'),
    pagination: () => import('@/components/widget/PaginationWidget.vue'),
  }
}
</script>
